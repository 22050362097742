import {
	NgModule,
	ApplicationRef,
	Inject,
	DoBootstrap,
	Injector,
	ErrorHandler,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {
	HttpClientModule,
	HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import {
	CommonAppDataService,
} from '../app_data/app_data.service';
import {
	COMMON_APP_CONFIG,
	ICommonAppConfig,
} from './app.config.token';
import { CommonLocaleModule } from '../locale/locale.module';
import { CommonRelativeRequestsInterceptor } from '../interceptors/relative-requests-interceptor.service';
import { CommonErrorInterceptor } from '../interceptors/error-interceptor.service';
import { CommonRequestCountInterceptor } from '../interceptors/request-count-interceptor.service';
import { CommonPipesModule } from '../pipes/pipes.module';
import { CommonBootstrapService } from '../bootstrap/bootstrap.service';
import { CommonVendorModule } from '../vendor/vendor.module';
import { CommonPopupModule } from '../popup/popup.module';
import { CommonUrlFiltersModule } from '../url_filters/common_url_filters.module';
import { CommonErrorHandlerService } from '../error-handler.service/error-handler.service';
import { CommonPageTitleModule } from '../page-title/page-title.module';
import { CommonContentWrapperModule } from '../content-wrapper/common-content-wrapper.module';
import { UserDataUpgradedServiceProvider } from '../user/user-data-upgraded.service';
import { CommonAppFontsModule } from './fonts/app-fonts.module';
import { CommonAppDataModule } from '../app_data/app-data.module';
import { CommonUserDataModule } from '../user/user-data.module';
import { CommonGeneralSettingsModule } from '../general_settings/general-settings.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonServerDateModule } from '../server_date/server-date.module';
import { CommonSharedLinkInterceptor } from '../interceptors/shared-link-interceptor.service';
import { CommonHeadersInterceptor } from '@CaseOne/Common/interceptors/headers-interceptor.service';
import { CommonCheckInactiveModule } from '@CaseOne/Common/common-check-inactive/common-check-inactive.module';


@NgModule({
	imports: [
		// Import BrowserModule, BrowserAnimationsModule, HttpModule or HttpClientModule only once, preferably in your root module.
		// Error when lazy loading modules
		// https://stackoverflow.com/a/45979219
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,

		FormsModule,
		HttpClientModule,
		CommonVendorModule,
		CommonLocaleModule,
		CommonAppFontsModule,
		CommonPipesModule,
		CommonPopupModule,
		CommonUrlFiltersModule,
		CommonPageTitleModule,
		CommonContentWrapperModule,
		CommonServerDateModule,
		CommonCheckInactiveModule,
	],
	exports: [
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		FormsModule,
		HttpClientModule,
		CommonLocaleModule,
		CommonGeneralSettingsModule,
		CommonAppDataModule,
		CommonUserDataModule,
		CommonPipesModule,
		CommonUrlFiltersModule,
		CommonPageTitleModule,
		CommonContentWrapperModule,
		CommonServerDateModule,
		CommonCheckInactiveModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CommonRelativeRequestsInterceptor,
			multi: true,
			deps: [CommonAppDataService],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CommonErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CommonRequestCountInterceptor,
			multi: true,
		},
		{
			provide: ErrorHandler,
			useClass: CommonErrorHandlerService,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CommonSharedLinkInterceptor,
			multi: true,
			deps: [CommonAppDataService],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CommonHeadersInterceptor,
			multi: true,
		},
		CommonBootstrapService,
		UserDataUpgradedServiceProvider,
	],
})
export class CommonAppModule implements DoBootstrap {
	app: ApplicationRef = this.injector.get(ApplicationRef);

	constructor (
		protected injector: Injector,
		@Inject(COMMON_APP_CONFIG) protected appConfig: ICommonAppConfig,
	) {}

	ngDoBootstrap () {
		const appModuleRunSubscribe$ = this.appModuleRun(this.app)
			.subscribe(() => {
				appModuleRunSubscribe$.unsubscribe();
			});
	}

	appModuleRun (
		app: ApplicationRef,
	): Observable<any> {
		return new Observable((subscriber) => {
			if (this.appConfig.mainComponent) {
				app.bootstrap(this.appConfig.mainComponent);
			}

			// wait subscribers
			setTimeout(() => {
				subscriber.next();
				subscriber.complete();
			});
		});
	}
}
