import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CommonDialogComponent } from './common-dialog.component';
import { CommonDialogButtonComponent } from './common-dialog-button.component/common-dialog-button.component';
import { CommonDialogContextMenuScreenComponent } from './common-dialog-context-menu-screen.component/common-dialog-context-menu-screen.component';
import { CommonDialogButtonsComponent } from './common-dialog-buttons.component/common-dialog-buttons.component';
import { CommonLoaderModule } from '@CaseOne/Common/loader/loader.module';
import { CommonLocaleModule } from '@CaseOne/Common/locale/locale.module';


@NgModule({
	imports: [
		CommonModule,
		CommonLoaderModule,
		CommonLocaleModule,
	],
	exports: [
		CommonDialogComponent,
		CommonDialogButtonComponent,
		CommonDialogButtonsComponent,
		CommonDialogContextMenuScreenComponent,
	],
	declarations: [
		CommonDialogComponent,
		CommonDialogButtonComponent,
		CommonDialogButtonsComponent,
		CommonDialogContextMenuScreenComponent,
	],
	entryComponents: [
		CommonDialogContextMenuScreenComponent,
	],
})
export class CommonDialogModule {}
