import { NgModule } from '@angular/core';
import { HotkeysModule } from '@ngneat/hotkeys';

import { CommonHotkeyService } from './services/hotkey.service';
import { CommonPermissionsModule } from '@CaseOne/Common/permissions/permissions.module';
import { CommonPopupModule } from '@CaseOne/Common/popup/popup.module';

@NgModule({
	imports: [
		HotkeysModule,
		CommonPermissionsModule,
		CommonPopupModule,
	],
	providers: [
		CommonHotkeyService,
	],
})
export class CommonHotkeyModule {}
