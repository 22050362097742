import { NgModule } from '@angular/core';
import { CommonPermissionsService } from './services/permissions.service';
import { CommonPermissionsParsersService } from './services/permissions-parsers.service';

@NgModule({
	providers: [
		CommonPermissionsService,
		CommonPermissionsParsersService,
	],
})
export class CommonPermissionsModule {}
